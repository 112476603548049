nav {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  background-color: #ddd;
  box-shadow: inset 0px -5px 4px -4px #bbb;
}

nav a {
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  padding: 10px 5px;
  display: block;
  text-align: center;
}

nav a.active {
  font-weight: bold;
  background-color: white;
}

/* child components */

.item {
  margin: 5px 10px;
}
.pending {
  opacity: 0.3;
}
.settings .item {
  margin: 9px 10px 15px;
}
.settings .item a {
  display: block;
}
.settings .item span {
  font-weight: bold;
  font-size: 1.2em;
}

h2 {
  margin: 15px 10px 5px;
  font-size: 1.3em;
}
h2 span {
  font-weight: normal;
}
h3 {
  margin: 0 0 8px;
  font-size: 1.1em;
}

form.inline {
  background-color: #FF9;
  padding: 10px;
  border-bottom: 1px solid black;
}

form a.close {
  position: absolute;
  right: 10px;
}

form a.delete {
  position: absolute;
  right: 17px;
  bottom: 17px;
  color: #e33;
}

input.hidden {
  display: none;
}

.group {
  padding: 2px 0 0;
}
.group input[type=number] {
  display: inline-block;
  width: 50px;
  margin-right: 8px;
}

ul {
  padding: 0;
  margin: 8px 0;
  list-style: none;
}

form img {
  height: 100px;
}
form p {
  margin: 8px 0;
}
form .comment {
  font-style: italic;
  white-space: pre-line;
  margin: 3px 0;
  padding: 0 8px;
  display: block;
  border-left: 3px solid rgba(0, 0, 0, .5);
}
form a {
  color: #00C;
}

.logout {
  margin: 90px 10px 20px;
  text-align: right;
}

/* Accounting */

table {
  margin: 10px;
  border: 1px solid #aaa;
  border-collapse: collapse;
  width: calc(100% - 20px);
  max-width: 500px;
}
th, td {
  text-align: left;
  border: 1px solid #ccc;
  padding: 2px 5px;
}
tr.tmp {
  font-style: italic;
}
@media (max-width: 400px) {
  table {
    font-size: 0.8em;
  }
}
footer {
  margin: 10px;
}

form[name=login] {
  text-align: center;
  margin-top: 25vh;
  padding: 20px;
}

form[name=login] input, form[name=login] button {
  display: block;
  width: calc(100% - 22px);
  max-width: 400px;
  margin: 20px auto;
  padding: 10px;
  border: 1px solid #aaa;
}
form[name=login] button {
  width: 150px;
  cursor: pointer;
}

a {
  cursor: pointer;
  color: black;
  text-decoration: none;
}

.error {
  color: #E33;
}

/* Icons */

svg {
  width: 32px;
  height: 32px;
}

/* Forms */

form {
  position: relative;
}
label {
  display: block;
  cursor: pointer;
  width: 90%;
  max-width: 400px;
  margin: 5px 0;
}
label.fullwidth {
  max-width: 90%;
}
input, select, button, textarea {
  font-size: 1em;
  margin: 3px 0;
  vertical-align: middle;
  border: 1px solid #bbb;
}
input, select, textarea {
  width: 100%;
}
select {
  height: 30px;
}
input, textarea {
  padding: 5px;
  width: calc(100% - 10px);
}
input[type=radio], input[type=checkbox] {
  width: auto;
  vertical-align: 2px;
  margin-right: 4px;
}
button {
  margin: 20px 10px;
  cursor: pointer;
  padding: 5px 10px;
}
button[type=submit] {
  background-color: #eee;
  margin: 20px 0 10px;
  border: 1px solid #aaa;
}
input::-webkit-input-placeholder {
  color: #ccc;
}
input::-ms-input-placeholder {
  color: #ccc;
}
input::placeholder {
  color: #ccc;
}

