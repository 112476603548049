a {
  cursor: pointer;
  color: black;
  text-decoration: none;
}

.error {
  color: #E33;
}

/* Icons */

svg {
  width: 32px;
  height: 32px;
}

/* Forms */

form {
  position: relative;
}
label {
  display: block;
  cursor: pointer;
  width: 90%;
  max-width: 400px;
  margin: 5px 0;
}
label.fullwidth {
  max-width: 90%;
}
input, select, button, textarea {
  font-size: 1em;
  margin: 3px 0;
  vertical-align: middle;
  border: 1px solid #bbb;
}
input, select, textarea {
  width: 100%;
}
select {
  height: 30px;
}
input, textarea {
  padding: 5px;
  width: calc(100% - 10px);
}
input[type=radio], input[type=checkbox] {
  width: auto;
  vertical-align: 2px;
  margin-right: 4px;
}
button {
  margin: 20px 10px;
  cursor: pointer;
  padding: 5px 10px;
}
button[type=submit] {
  background-color: #eee;
  margin: 20px 0 10px;
  border: 1px solid #aaa;
}
input::placeholder {
  color: #ccc;
}
