form[name=login] {
  text-align: center;
  margin-top: 25vh;
  padding: 20px;
}

form[name=login] input, form[name=login] button {
  display: block;
  width: calc(100% - 22px);
  max-width: 400px;
  margin: 20px auto;
  padding: 10px;
  border: 1px solid #aaa;
}
form[name=login] button {
  width: 150px;
  cursor: pointer;
}
